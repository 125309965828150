@tailwind base;
@tailwind components;
@tailwind utilities;

.green {
  background-color: green;
}
@layer utilities {
  .selection ::selection {
    background-color: #feba12;
    color: black;
  }
}
